  /* Dropdown Button */
  .dropbtnfilter {
    background-color: #14161b;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 7px;
    margin-right: 5px;
    margin-left: 5px;
    z-index: 10;
    padding: 5px;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdownfilter {
    position: relative;
    display: inline-block;
    z-index: 10;

  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-contentfilter {
    display: none;
    position: absolute;
    background-color: #2139b7;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    width: 200px;
    border-radius: 10px;
    right: 0px !important;
  }

  /* Links inside the dropdown */
  .dropdown-contentfilter .link {
    color: #FFFFFF;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    z-index: 10;
    border-radius: 10px;
    margin: 10px;


  }

  /* Change color of dropdown links on hover */
  .dropdown-contentfilter .link:hover {
    background-color: #000000 !important;
  }

  /* Show the dropdown menu on hover */
  .dropdownfilter:hover .dropdown-contentfilter {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdownfilter:hover .dropbtnfilter {
    background-color: #000000;
  }