.p-icon{
  color: #fff;
}

.p-dropdown .p-dropdown-trigger {
  background-color: #000 !important;
}

.p-dropdown{
  background-color: #000 !important;
  border: 0px solid #000 !important;
}

.react-confirm-alert-overlay {
  background : rgba(0,0,0,0.7)
}
.react-confirm-alert-body {
  background-color: #14161b !important;
}

.react-confirm-alert-body > h1 {
  color: #fff;
  font-size: 8 !important;
}
.react-confirm-alert-button-group button  {
  background-color: #2139b7 !important;
}

text{
  color: #000;
}